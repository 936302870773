
.shuffle-btn:active{
    transform: rotate(720deg);
    transition: all 0.6s ease-out;
}

.bi-shuffle{
    height: 20px;
    width: 20px;
    opacity: 0.6;
}


